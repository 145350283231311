var LoadMore = {
    _selector: {
        container: '.js-listing-instants',
        resContainer: '.js-instants-results',
        btn: '#load-more',
    },

    current_page: 2,
    max_page: false,
    is_loading: false,


    init: function () {
        if (wp_debug) {
            console.log('Recherche initialisée');
        }
        LoadMore.listen();
    },

    listen: function () {

        $(LoadMore._selector.btn).on('click', function (e) {

            e.preventDefault();
            e.stopPropagation();
            LoadMore.loadmore();
        });
    },

    loadmore: function () {
        url = $(LoadMore._selector.btn).attr('href');

        if (url && LoadMore.is_loading === false) {
            LoadMore.is_loading = true;

            $.get(
                url,
                false,
                function (responseHTML) {
                    LoadMore.updateContent(responseHTML);
                    LoadMore.current_page++;
                    LoadMore.is_loading = false;
                }
            );
        }
    },

    updateContent: function (responseHTML) {

        var html = $(responseHTML).find(LoadMore._selector.resContainer).html();

        $(LoadMore._selector.resContainer).append(html);

        LoadMore.max_page = parseInt($(LoadMore._selector.btn).attr("data-maxpage"));

        lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });

        if(LoadMore.current_page == LoadMore.max_page){
            $(LoadMore._selector.btn).remove();
            $('.js-listing-pagination .gradient').remove();
        }else{
            var splited = $(LoadMore._selector.btn).attr('href').split('/page/')
            $(LoadMore._selector.btn).attr('href',splited[0] + "/page/" + LoadMore.current_page);
        }
        parseCardInstantClickOverlay();
    }

};

LoadMore.init();
