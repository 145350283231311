// Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
$('.js-lang-dropdown-mobile').selectric('destroy');

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('.js-lang-dropdown-mobile').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

// Changement du type de logo en fonction de la cover utilisée
const isHome = document.querySelectorAll('.cover-home.--has-full-image.--has-video');
const isEdito = document.querySelectorAll('.cover-edito.--has-full-image');
const colorLogos = document.querySelectorAll('.logo-color');
const whiteLogos = document.querySelectorAll('.logo-white');

if (isHome.length || isEdito.length > 0 ) {

    colorLogos.forEach(colorLogo => {
        colorLogo.style.display = 'none';
    })
}

else {
    whiteLogos.forEach(whiteLogo => {
        whiteLogo.style.display = 'none';
    })
}



