(function () {
    if (typeof IngenieMenuEngine !== "undefined") {
        let lang = document.documentElement.getAttribute('lang');
        let url = 'https://reservation.evian-tourisme.com/formulaire-prestataire-FR.html';

        if (!lang) {
            lang = 'fr-FR'
        }

        let urls = {
            'fr-FR': 'https://reservation.evian-tourisme.com/formulaire-prestataire-FR.html',
            'en-GB': 'https://booking.evian-tourisme.com/formulaire-prestataire-EN.html',
            'de-DE': 'https://buchung.evian-tourisme.com/formulaire-prestataire-DE.html',
        }

        if (urls[lang]) {
            url = urls[lang];
        }

        var widget = new IngenieMenuEngine.Client({
            el: 'widget-general',
            cid: 3,
            site: {
                url: url
            },
            engineTypes: [
                'MOTEUR_HEBERGEMENT',
                'MOTEUR_ACTIVITE'
            ],
            tamplateName: 'RESA',
            customCss: 'widget-engine-menu',
            referenceMenu: 'MOTEUR_RESA_V2',
            closeAt: 0,
            defaultEngineType: 'MOTEUR_HEBERGEMENT', // Onglet ouvert par défaut
            categoryImage: 'VISUSOMMAIRE',
            engineShowing: [ // Onglet à afficher
                'MOTEUR_HEBERGEMENT',
                'MOTEUR_ACTIVITE'
            ],
            typePrestataire: {
                'MOTEUR_HEBERGEMENT': 'G',
                'MOTEUR_ACTIVITE': 'V'
            },
            uiPrestataire: 'select'
        });
        widget.init();
    }

})();