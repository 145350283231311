class ViewMoreTags {
    containers = [];
    _selector = {
        btnMore : '.meta-more',
        selecteurItem : '[data-view-more-tag]'
    }
    _elements = [];
    currIndex = 0;
    delay = 0;

    constructor(parent, btnMore, selecteurItem, delay) {
        if(parent && btnMore && selecteurItem){
            this._selector.btnMore = btnMore;
            this._selector.selecteurItem;
        }
        this.containers = document.querySelectorAll(parent);

        this.containers.forEach((container, index) => {
           this.currIndex = index;
           this.selector(container);
           container.addEventListener('click', () => {
              this.openTags(index);
              this.removeBtnMore(index);
           });
        });
    }

    selector(container){
        let elements = [];

        elements['btnMore'] = container.querySelector(this._selector.btnMore);
        elements['selecteurItem'] = container.querySelectorAll(this._selector.selecteurItem);
        this._elements.push(elements);
    }

    openTags(index){

        this._elements[index]['selecteurItem'].forEach((item, i) => {
            setTimeout(() => {
                item.removeAttribute('data-view-more-tag');
            }, i * this.delay);
        });
    }

    removeBtnMore(index){
        this._elements[index]['btnMore'].remove();
    }
}