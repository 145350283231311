(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-logos');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();
    });

})();