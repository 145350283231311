function checkIfFrameIngenieInit(el) {
    if (typeof FrameIngenie != 'undefined') {
        setTimeout(initWidgetFrameIngenie(el), 100);
    } else {
        setTimeout(checkIfFrameIngenieInit(el), 100);
    }
}

function initWidgetFrameIngenie(el) {
    let urls = {
        'HEBERGEMENT_COLLECTIF': {
            'fr': 'https://reservation.evian-tourisme.com/formulaire-prestataire-hebergeur-FR.html',
            'en': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'uk': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'de': 'https://buchung.evian-tourisme.com/formulaire-prestataire-hebergeur-DE.html',
        },
        'HEBERGEMENT_LOCATIF': {
            'fr': 'https://reservation.evian-tourisme.com/formulaire-prestataire-hebergeur-FR.html',
            'en': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'uk': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'de': 'https://buchung.evian-tourisme.com/formulaire-prestataire-hebergeur-DE.html',
        },
        'HOTELLERIE': {
            'fr': 'https://reservation.evian-tourisme.com/formulaire-prestataire-hebergeur-FR.html',
            'en': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'uk': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'de': 'https://buchung.evian-tourisme.com/formulaire-prestataire-hebergeur-DE.html',
        },
        'HOTELLERIE_PLEIN_AIR': {
            'fr': 'https://reservation.evian-tourisme.com/formulaire-prestataire-hebergeur-FR.html',
            'en': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'uk': 'https://booking.evian-tourisme.com/formulaire-prestataire-hebergeur-EN.html',
            'de': 'https://buchung.evian-tourisme.com/formulaire-prestataire-hebergeur-DE.html',
        },
        'default': {
            'fr': 'https://reservation.evian-tourisme.com/formulaire-prestataire-FR.html',
            'en': 'https://booking.evian-tourisme.com/formulaire-prestataire-EN.html',
            'uk': 'https://booking.evian-tourisme.com/formulaire-prestataire-EN.html',
            'de': 'https://buchung.evian-tourisme.com/formulaire-prestataire-DE.html',
        }
    }

    const sit_id = el.getAttribute("data-sit-id");
    const lang = el.getAttribute("data-lang");
    let type = el.getAttribute("data-type");

    if (sit_id && lang && type) {
        if (!urls[type]) {
            type = "default";
        }

        const urlFormulaire = urls[type][lang] + "?id_fiche=" + sit_id;
        const objFrame = new FrameIngenie(urlFormulaire, 'overlay-contactsit-container');

        objFrame.init();
    }
}

th_overlay.addCallbackOpen(overlayId => {
    if (overlayId === "overlay-contactsit") {
        const el = document.querySelector(`#${overlayId}`);

        if (el && !el.classList.contains("parsed")) {
            el.classList.add("parsed");

            checkIfFrameIngenieInit(el);
        }
    }
})