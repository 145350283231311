(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-temps-forts');
    sliders.forEach(function(slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            autoWidth: false,
            lazyLoad: 'nearby',
        }).mount();
    }

})();