function parseCardFocusSITClickOverlay() {
    $('.js-focus-sit .card:not(.parsed)').on('click', function (e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href') + '?overlay';
        var overlay_id = 'overlay-focus-sit';

        $('#' + overlay_id + ' .bottom-part').addClass('hide');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .bottom-part').removeClass('hide');
        });

        th_overlay.open(overlay_id, true, true, false);

    }).addClass('parsed');
}

parseCardFocusSITClickOverlay();